
import { defineComponent } from 'vue';
import { VueEditor } from "vue3-editor";
export default defineComponent({
  name : 'SuiteInput',
  components: {
    VueEditor
  },
  props:{
    type:{
      type:String,
      default:"text"
    },
    placeholder:{
      type:String,
      default:" "
    },
    required:{
      type: Boolean,
      default: false
    },
    minLength:{
      type:Number,
      default:3
    },
    validation:{
      type: RegExp,
      default: undefined
    },
    label:{
      type: String,
      default: null
    },
    disabled:{
      type: Boolean,
      default:false
    },
    modelValue:[String, Number],
    withStyles:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      errorMessage:"",
      error: false,
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        //[{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        //[{ 'header': 1 }, { 'header': 2 }],
        //['blockquote', 'code-block'],
        //[{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],
        //[{ 'indent': '-1'}, { 'indent': '+1' }],
        //[{ 'color': [] }, { 'background': [] }],
        //['link', 'formula'],
        //[{ 'direction': 'rtl' }],
        ['clean'],
      ]
    }
  },
  emits: ['update:modelValue','data-update'],
  watch: {
    value (newValue) {
     this.$emit('update:modelValue', newValue)
   }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(newValue: any) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  methods:{
    hasErrors(){
      this.error = false
      if (this.isRequiredError()) {
        this.error = true
        this.errorMessage = "Este campo es obligatorio"
      }
      else if(!this.isValidFormat()){
        this.error = true
        this.errorMessage = "Introduce un formato correcto"
      }
      return this.error
    },
    isEmpty(){
      return this.value == undefined || this.value == null || this.value == ''
    },
    isRequiredError(){
      return this.required && this.isEmpty() || (this.required && this.value!= undefined && this.value.toString().length < this.minLength)
    },
    isValidFormat(){
      if(!this.validation || this.value == undefined || this.value == null || this.value == '') return true
      return this.value.toString().match(this.validation)
    }
  }
})
